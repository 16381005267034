import { q, sanityImage, Selection } from 'groqd';

export const selectionSanityProductListing = {
  _id: q.string(),
  handle: ['slug.current', q.string()],
  productType: q.string(),
  thumbnail: sanityImage('thumbnail', {
    isList: true,
    withAsset: ['base', 'blurHash', 'dimensions'],
  }),
  shopifyGid: q.string(),
  title: ['coalesce(displayTitle, shopifyTitle)', q.string()],
  // variants: q('variants').filter().grab({
  //   _key: q.string(),
  //   shopifyGid: q.string(),
  //   sku: q.string(),
  //   title: q.string(),
  // }),
} satisfies Selection;

export const selectionSanityImage = {
  _id: q.string(),
  _type: q.string(),
  // _rev: q.string(),
  // _updatedAt: q.string(),
  extension: q.string(),
  mimeType: q.string(),
  originalFilename: q.string(),
  path: q.string(),
  sha1hash: q.string(),
  size: q.number(),
  url: q.string(),
  metadata: q('metadata').grab({
    blurHash: q.string().nullable(),
    dimensions: q
      .object({
        aspectRatio: q.number(),
        width: q.number(),
        height: q.number(),
      })
      .nullable(),
  }),
} satisfies Selection;

export const selectionSanitySizeChart = {
  rows: q('rows')
    .filter()
    .grab({
      _key: q.string(),
      _type: q.string(),
      cells: q.array(q.string()),
    }),
} satisfies Selection;

export const selectionSanitySizeGuide = {
  _id: q.string(),
  chart: q('chart').grab(selectionSanitySizeChart),
  notes: q.contentBlocks(),
  title: q.string(),
} satisfies Selection;

export const querySanityProduct = q('*')
  .filter('_type == "product" && slug.current == $slug')
  .slice(0)
  .grab({
    _id: q.string(),
    handle: ['slug.current', q.string()],
    description: q.contentBlocks(),
    images: sanityImage('images', {
      isList: true,
      withAsset: ['base', 'blurHash', 'dimensions'],
    }),
    productType: q.string(),
    shopifyGid: q.string(),
    title: ['coalesce(displayTitle, shopifyTitle)', q.string()],
    sizeGuides: q('sizeGuides').filter().deref().grab(selectionSanitySizeGuide),
    variants: q('variants').filter().grab({
      _key: q.string(),
      shopifyGid: q.string(),
      sku: q.string(),
      title: q.string(),
    }),
  });

export const querySanityProducts = q('*')
  .filter('_type == "product" && shopifyGid in $gids')
  .grab(selectionSanityProductListing);

export const querySanitySettings = q('*')
  .filter('_id == "settings"')
  .slice(0)
  .grab({
    text: q('text').grab({
      banner: q.contentBlocks().optional(),
      delivery: q.contentBlocks().optional(),
      cookies: q.contentBlocks().optional(),
      locale: q.contentBlocks().optional(),
    }),
    meta: q('meta')
      .grab({
        description: q.string().optional(),
        image: sanityImage('image'),
      })
      .nullable(),
    social: q('socialMedia').filter().grab({
      _key: q.string(),
      _type: q.string(),
      title: q.string(),
      url: q.string(),
    }),
  });

export const querySanityStockists = q('*')
  .filterByType('stockist')
  .grab({
    _id: q.string(),
    country: ['address.country', q.string()],
    title: q.string(),
    website: q.string(),
  })
  .order('title asc');

export const selectionSanityLookbookImage = {
  _key: q.string(),
  _type: q.literal('image'),
  asset: q('asset').deref().grab(selectionSanityImage),
  display: q.string().optional(),
  alt: q.string().optional(),
  caption: q.contentBlocks().optional(),
} satisfies Selection;

export const selectionSanityLookbookVideo = {
  _key: q.string(),
  _type: q.literal('video'),
  asset: q.object({
    _ref: q.string(),
    _type: q.literal('reference'),
  }),
  display: q.string().optional(),
  alt: q.string().optional(),
  caption: q.contentBlocks().optional(),
} satisfies Selection;

export const selectionSanityLookbook = {
  _id: q.string(),
  handle: ['slug.current', q.string()],
  description: q.contentBlocks(),
  media: q('media').filter().grab(
    {},
    {
      // Can't seem to use sanityImage() here
      "_type == 'image'": selectionSanityLookbookImage,
      "_type == 'video'": selectionSanityLookbookVideo,
    },
  ),
  title: q.string(),
  meta: q('meta')
    .grab({
      description: q.string().optional(),
    })
    .nullable(),
} satisfies Selection;

export const querySanityLookbookLatest = q('*')
  .filterByType('lookbook')
  .order('date desc')
  .slice(0)
  .grab(selectionSanityLookbook);

export const querySanityLookbookHandle = q('*')
  .filterByType('lookbook')
  .filter('slug.current == $slug')
  .order('date desc')
  .slice(0)
  .grab(selectionSanityLookbook);

export const querySanityLookbooks = q('*')
  .filterByType('lookbook')
  .order('date desc')
  .grab({
    _id: q.string(),
    handle: ['slug.current', q.string()],
    title: q.string(),
  });

export const querySanityMixes = q('*')
  .filterByType('mix')
  .grab({
    _id: q.string(),
    artwork: sanityImage('artwork', {
      additionalFields: {
        caption: q.contentBlocks().optional(),
      },
      withAsset: ['base', 'blurHash', 'dimensions'],
    }),
    number: [
      'count(*[_type == "mix" && soundcloud.created < ^.soundcloud.created]) + 1',
      q.number(),
    ],
    handle: ['slug.current', q.string()],
    sound: q.object({
      _type: q.string(),
      asset: q.object({
        _ref: q.string(),
        _type: q.literal('reference'),
      }),
    }),
    soundcloud: q('soundcloud').grab({
      duration: q.number(),
      genre: q.string(),
      stream: q.string(),
    }),
    title: q.string(),
  })
  .order('number desc');

export const querySanityBlogPosts = q('*')
  .filterByType('blogPost')
  .order('published_on desc')
  .grab({
    _id: q.string(),
    handle: ['slug.current', q.string()],
    published_on: q.string(),
    thumbnail: sanityImage('thumbnail', {
      withAsset: ['base', 'blurHash', 'dimensions'],
    }),
    title: q.string(),
  });

export const querySanityBlogPost = q('*')
  .filterByType('blogPost')
  .filter('slug.current == $slug')
  .slice(0)
  .grab({
    _id: q.string(),
    body: q.contentBlocks(),
    handle: ['slug.current', q.string()],
    published_on: q.string(),
    title: q.string(),
    meta: q('meta')
      .grab({
        description: q.string().optional(),
      })
      .nullable(),
  });

export const querySanityInformation = q('*')
  .filterByType('pageInformation')
  .grab({
    _id: q.string(),
    content: q.contentBlocks(),
    handle: ['slug.current', q.string()],
    title: q.string(),
  });

export const querySanityNewsletterPage = q('*')
  .filterByType('pageNewsletter')
  .slice(0)
  .grab({
    content: q.contentBlocks(),
    title: q.string(),
    meta: q('meta')
      .grab({
        description: q.string().optional(),
      })
      .nullable(),
  });

export const querySanityCartProducts = q('*')
  .filter('_type == "product" && shopifyGid in $gids')
  .grab({
    _id: q.string(),
    thumbnail: sanityImage('thumbnail', {
      isList: true,
      withAsset: ['base', 'blurHash', 'dimensions'],
    }).slice(0),
    shopifyGid: q.string(),
    title: ['coalesce(displayTitle, shopifyTitle)', q.string()],
    variants: q('variants').filter().grab({
      _key: q.string(),
      shopifyGid: q.string(),
      title: q.string(),
    }),
  });

export const selectionSanitySectionRow = {
  _type: q.literal('sectionRow'),
  text: q.contentBlocks().optional(),
  space: q.boolean().optional(),
  row: q('row')
    .filter()
    .grab({
      _type: q.string(),
      _key: q.string(),
      align: q.string().optional(),
      bgColour: q.object({ value: q.string() }).optional(),
      video: sanityImage('video'),
      videoMobile: sanityImage('videoMobile').nullable(),
      image: sanityImage('image', {
        withAsset: ['base', 'blurHash', 'dimensions'],
      }),
      imageMobile: sanityImage('imageMobile', {
        withAsset: ['base', 'blurHash', 'dimensions'],
      }).nullable(),
      link: q.string().optional(),
      text: q.string().optional(),
      textColour: q.object({ value: q.string() }).optional(),
    }),
} satisfies Selection;

export const selectionSanitySectionInformation = {
  _type: q.literal('sectionInformation'),
  _key: q.string(),
  heading: q.string(),
  subheading: q.string().optional(),
  cta: q.string().optional(),
  link: q.string().optional(),
  textColour: q.object({ value: q.string() }).optional(),
  bgColour: q.object({ value: q.string() }).optional(),
  removeSpace: q.boolean().optional(),
} satisfies Selection;

export const querySanityHome = q('*')
  .filterByType('home')
  .slice(0)
  .grab({
    sections: q('sections').filter().grab(
      {},
      {
        "_type == 'sectionRow'": selectionSanitySectionRow,
        "_type == 'sectionInformation'": selectionSanitySectionInformation,
      },
    ),
  });

export const querySanitySearch = q('*')
  .filterByType('product')
  .filter('[coalesce(displayTitle, shopifyTitle)] match "*"+$query+"*"')
  .grab(selectionSanityProductListing);
