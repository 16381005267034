const fragmentProductListing = gql`
  fragment ProductListingFields on Product {
    id
    availableForSale
    totalInventory
    priceRange {
      minVariantPrice {
        amount
        currencyCode
      }
      maxVariantPrice {
        amount
        currencyCode
      }
    }
    compareAtPriceRange {
      minVariantPrice {
        amount
        currencyCode
      }
      maxVariantPrice {
        amount
        currencyCode
      }
    }
  }
`;

const fragmentCart = gql`
  fragment CartFields on Cart {
    id
    buyerIdentity {
      countryCode
    }
    checkoutUrl
    totalQuantity
    cost {
      subtotalAmountEstimated
      subtotalAmount {
        amount
        currencyCode
      }
      totalAmountEstimated
      totalAmount {
        amount
        currencyCode
      }
      totalDutyAmountEstimated
      totalDutyAmount {
        amount
        currencyCode
      }
      totalTaxAmountEstimated
      totalTaxAmount {
        amount
        currencyCode
      }
    }
    deliveryGroups(first: 100) {
      nodes {
        selectedDeliveryOption {
          code
          handle
          title
          estimatedCost {
            amount
            currencyCode
          }
        }
        deliveryOptions {
          code
          handle
          title
          estimatedCost {
            amount
            currencyCode
          }
        }
      }
    }
    lines(first: 100) {
      nodes {
        id
        quantity
        cost {
          amountPerQuantity {
            amount
            currencyCode
          }
          compareAtAmountPerQuantity {
            amount
            currencyCode
          }
          subtotalAmount {
            amount
            currencyCode
          }
          totalAmount {
            amount
            currencyCode
          }
        }
        merchandise {
          ... on ProductVariant {
            id
            product {
              id
              handle
            }
          }
        }
      }
    }
  }
`;

export const queryShopifyProduct = gql`
  query Product($handle: String!, $country: CountryCode = GB)
  @inContext(country: $country) {
    product(handle: $handle) {
      id
      availableForSale
      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      compareAtPriceRange {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      variants(first: 100) {
        nodes {
          id
          availableForSale
          price {
            amount
            currencyCode
          }
          compareAtPrice {
            amount
            currencyCode
          }
        }
      }
    }
  }
`;

export const queryShopifyProducts = gql`
  ${fragmentProductListing}
  query Products($collection: String!, $country: CountryCode = GB)
  @inContext(country: $country) {
    collection(handle: $collection) {
      products(first: 100) {
        nodes {
          ...ProductListingFields
        }
      }
    }
  }
`;

export const queryShopifySearch = gql`
  ${fragmentProductListing}
  query Products($query: String!, $country: CountryCode = GB)
  @inContext(country: $country) {
    products(query: $query, first: 100) {
      nodes {
        ...ProductListingFields
      }
    }
  }
`;

export const queryShopifyLocalizations = gql`
  query Localizations {
    localization {
      country {
        isoCode
        name
        currency {
          isoCode
          name
          symbol
        }
        market {
          handle
          id
        }
      }
      availableCountries {
        isoCode
        name
        currency {
          isoCode
          name
          symbol
        }
        market {
          handle
          id
        }
      }
    }
  }
`;

export const queryShopifyCart = gql`
  ${fragmentCart}
  query Cart($id: ID!) {
    cart(id: $id) {
      ...CartFields
    }
  }
`;

export const mutationShopifyCartCreate = gql`
  ${fragmentCart}
  mutation CartCreate($input: CartInput!) {
    cartCreate(input: $input) {
      cart {
        ...CartFields
      }
      userErrors {
        field
        message
      }
    }
  }
`;

export const mutationShopifyCartLinesAdd = gql`
  ${fragmentCart}
  mutation CartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
    cartLinesAdd(cartId: $cartId, lines: $lines) {
      cart {
        ...CartFields
      }
      userErrors {
        field
        message
      }
    }
  }
`;

export const mutationShopifyCartLinesRemove = gql`
  ${fragmentCart}
  mutation CartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
    cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
        ...CartFields
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export const mutationShopifyCartBuyerIdentityUpdate = gql`
  ${fragmentCart}
  mutation CartBuyerIdentityUpdate(
    $cartId: ID!
    $buyerIdentity: CartBuyerIdentityInput!
  ) {
    cartBuyerIdentityUpdate(cartId: $cartId, buyerIdentity: $buyerIdentity) {
      cart {
        ...CartFields
      }
      userErrors {
        field
        message
      }
    }
  }
`;

export const queryShopifyShop = gql`
  query Shop {
    shop {
      paymentSettings {
        acceptedCardBrands
        # countryCode
        # currencyCode
        # enabledPresentmentCurrencies
        supportedDigitalWallets
      }
    }
  }
`;
