import { computed } from 'vue';
import { defineStore } from 'pinia';
import { useLocale } from '@/stores/locale';
import type { ShopifyCart } from '@/types/shopify';
import {
  mutationShopifyCartBuyerIdentityUpdate,
  mutationShopifyCartCreate,
  mutationShopifyCartLinesAdd,
  mutationShopifyCartLinesRemove,
  queryShopifyCart,
} from '@/queries/shopify';

interface Merchandise {
  id: string;
  quantity?: number;
}

export const useCart = defineStore('cart', () => {
  const { client } = useApolloClient();

  const cartId = useCookie<string | null>('heresy/cart-id', {
    default: () => null,
    maxAge: 60 * 60 * 24 * 90,
  });

  const cart = ref<ShopifyCart | null>(null);

  const count = computed(() => {
    return cart.value?.totalQuantity || 0;
  });

  const pretty = computed(() => {
    if (count.value) {
      return `Cart (${count.value})`;
    }
    return 'Cart';
  });

  const locale = useLocale();

  async function create(merchandise: Merchandise) {
    const variables = {
      input: {
        buyerIdentity: {
          countryCode: locale.locale.isoCode,
        },
        lines: {
          merchandiseId: merchandise.id,
          quantity: merchandise.quantity || 1,
        },
      },
    };

    const { data } = await client.mutate<{
      cartCreate: { cart: ShopifyCart };
    }>({
      mutation: mutationShopifyCartCreate,
      variables,
    });
    if (data) {
      cartId.value = data.cartCreate.cart.id;
      cart.value = data.cartCreate.cart;
    }
  }

  async function add(merchandise: Merchandise) {
    if (!cart.value) {
      return await create(merchandise);
    }

    const variables = {
      cartId: cart.value.id,
      lines: {
        merchandiseId: merchandise.id,
        quantity: merchandise.quantity || 1,
      },
    };

    const { data } = await client.mutate<{
      cartLinesAdd: { cart: ShopifyCart };
    }>({
      mutation: mutationShopifyCartLinesAdd,
      variables,
    });
    if (data) {
      cart.value = data.cartLinesAdd.cart;
    }
  }

  async function remove(lineId: string) {
    if (!cart.value) {
      return;
    }
    const variables = {
      cartId: cart.value.id,
      lineIds: [lineId],
    };
    const { data } = await client.mutate<{
      cartLinesRemove: { cart: ShopifyCart };
    }>({
      mutation: mutationShopifyCartLinesRemove,
      variables,
    });
    if (data) {
      cart.value = data.cartLinesRemove.cart;
    }
  }

  async function updateIdentity() {
    if (cart.value) {
      const { data } = await client.mutate<{
        cartBuyerIdentityUpdate: { cart: ShopifyCart };
      }>({
        mutation: mutationShopifyCartBuyerIdentityUpdate,
        variables: {
          cartId: cart.value.id,
          buyerIdentity: {
            countryCode: locale.locale.isoCode,
          },
        },
      });
      if (data) {
        cart.value = data.cartBuyerIdentityUpdate.cart;
      }
    }
  }

  async function refresh() {
    if (cartId.value) {
      try {
        const { data } = await client.query<{ cart: ShopifyCart | null }>({
          query: queryShopifyCart,
          variables: { id: cartId.value },
        });
        cart.value = data.cart;
      } catch {
        cartId.value = null;
        cart.value = null;
      }
    }
  }

  return {
    add,
    cart,
    count,
    pretty,
    refresh,
    remove,
    updateIdentity,
  };
});
