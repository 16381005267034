import { useLocale } from '@/stores/locale';
import { useSettings } from '@/stores/settings';
import { useCart } from '@/stores/cart';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('app:created', async () => {
    const cart = useCart();
    const locale = useLocale();
    const settings = useSettings();
    await Promise.all([settings.init(), locale.init()]);
    await cart.refresh();
  });
});
