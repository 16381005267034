import { ref } from 'vue';
import { defineStore } from 'pinia';
import { nanoid } from 'nanoid';
import { BaseNotification } from '@/types';

export const useNotifications = defineStore('notifications', () => {
  const notifications = ref<BaseNotification[]>([]);

  function add({ text, type, duration, icon }: Omit<BaseNotification, 'id'>) {
    const id = nanoid();
    const notification = {
      duration,
      icon,
      id,
      text,
      type,
    };
    notifications.value.unshift(notification);
  }

  function remove(id: string) {
    const index = notifications.value.findIndex((n) => n.id === id);
    notifications.value.splice(index, 1);
  }

  return { notifications, add, remove };
});
