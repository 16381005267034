import imageUrlBuilder from '@sanity/image-url';

export const useSanityImage = () => {
  const config = useRuntimeConfig();
  const sanity = useSanity();
  const sanityImage = imageUrlBuilder({
    projectId: sanity.config.projectId,
    dataset: sanity.config.dataset || '',
  });
  sanityImage.options.baseUrl = config.public.image.cdnUrl;
  return sanityImage;
};
