import { ref } from 'vue';
import { defineStore, skipHydrate } from 'pinia';
import { ShopifyCountry } from '@/types/shopify';
import { queryShopifyLocalizations } from '@/queries/shopify';

export const useLocale = defineStore('locale', () => {
  const locales = ref<ShopifyCountry[]>([]);
  // Dummy data, gets replaced immediately
  const locale = ref<ShopifyCountry>({
    isoCode: 'GB',
    name: 'United Kingdom',
    currency: {
      isoCode: 'GBP',
      name: 'British Pound',
      symbol: '£',
      __typename: 'Currency',
    },
    market: {
      handle: 'gb',
      id: 'gid://shopify/Market/1131348212',
      __typename: 'Market',
    },
    __typename: 'Country',
  });

  const saved = useCookie<string | null>('heresy/locale', {
    default: () => null,
    maxAge: 60 * 60 * 24 * 90,
  });

  function setLocales(countries: ShopifyCountry[]) {
    locales.value = countries;
  }

  function setLocale(countryCode: string, save = false) {
    const country = locales.value.find(
      (locale) => locale.isoCode === countryCode,
    );
    if (country) {
      locale.value = country;
      if (save) {
        saved.value = countryCode;
      }
    }
  }

  const pretty = computed(() => {
    return `${locale.value.isoCode} (${locale.value.currency.symbol})`;
  });

  async function init() {
    // Fetch localization data
    const { data: localeData } = await useAsyncQuery<{
      localization: {
        availableCountries: ShopifyCountry[];
        country: ShopifyCountry;
      };
    } | null>(queryShopifyLocalizations);
    // If we don't get localization data, bail... but maybe throw an error too?
    if (!localeData.value) return;

    const locales = [...localeData.value.localization.availableCountries].sort(
      (a, b) => a.name.localeCompare(b.name),
    );
    // Set the locale list
    setLocales(locales);
    // See if we have a valid saved locale isoCode
    const validSavedLocale =
      localeData.value.localization.availableCountries.find(
        (l) => l.isoCode === saved.value,
      );
    // Set the locale
    setLocale(
      validSavedLocale?.isoCode ||
        localeData.value.localization.country.isoCode,
    );
  }

  return {
    init,
    locale,
    locales,
    pretty,
    saved,
    setLocale,
    setLocales,
  };
});
