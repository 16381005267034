import { default as _91post_93xii0exAgZPMeta } from "/tmp/build/pages/blog/[post].vue?macro=true";
import { default as indexCi2QtLYRVwMeta } from "/tmp/build/pages/blog/index.vue?macro=true";
import { default as bloglsO2ndmlEwMeta } from "/tmp/build/pages/blog.vue?macro=true";
import { default as cartJJwIC44FCoMeta } from "/tmp/build/pages/cart.vue?macro=true";
import { default as indexKDjUiNtftQMeta } from "/tmp/build/pages/index.vue?macro=true";
import { default as _91_91section_93_93lZlbrwrx57Meta } from "/tmp/build/pages/information/[[section]].vue?macro=true";
import { default as _91_91lookbook_93_93thOEQmau6qMeta } from "/tmp/build/pages/lookbooks/[[lookbook]].vue?macro=true";
import { default as newsletter4sk6iuqUHLMeta } from "/tmp/build/pages/newsletter.vue?macro=true";
import { default as _91_91noise_93_938JU4KLuzSWMeta } from "/tmp/build/pages/noise/[[noise]].vue?macro=true";
import { default as searchz9mA7rpO26Meta } from "/tmp/build/pages/search.vue?macro=true";
import { default as _91product_9352veR8s6QtMeta } from "/tmp/build/pages/shop/[product].vue?macro=true";
import { default as indexZrzggXNnOXMeta } from "/tmp/build/pages/shop/index.vue?macro=true";
import { default as shopi8QZLDhVT9Meta } from "/tmp/build/pages/shop.vue?macro=true";
import { default as stockistsQt5r5ema3PMeta } from "/tmp/build/pages/stockists.vue?macro=true";
export default [
  {
    name: bloglsO2ndmlEwMeta?.name,
    path: "/blog",
    component: () => import("/tmp/build/pages/blog.vue"),
    children: [
  {
    name: "blog-post",
    path: ":post()",
    component: () => import("/tmp/build/pages/blog/[post].vue")
  },
  {
    name: "blog",
    path: "",
    component: () => import("/tmp/build/pages/blog/index.vue")
  }
]
  },
  {
    name: "cart",
    path: "/cart",
    component: () => import("/tmp/build/pages/cart.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/tmp/build/pages/index.vue")
  },
  {
    name: "information-section",
    path: "/information/:section?",
    component: () => import("/tmp/build/pages/information/[[section]].vue")
  },
  {
    name: "lookbooks-lookbook",
    path: "/lookbooks/:lookbook?",
    component: () => import("/tmp/build/pages/lookbooks/[[lookbook]].vue")
  },
  {
    name: "newsletter",
    path: "/newsletter",
    component: () => import("/tmp/build/pages/newsletter.vue")
  },
  {
    name: "noise-noise",
    path: "/noise/:noise?",
    component: () => import("/tmp/build/pages/noise/[[noise]].vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/tmp/build/pages/search.vue")
  },
  {
    name: shopi8QZLDhVT9Meta?.name,
    path: "/shop",
    component: () => import("/tmp/build/pages/shop.vue"),
    children: [
  {
    name: "shop-product",
    path: ":product()",
    component: () => import("/tmp/build/pages/shop/[product].vue")
  },
  {
    name: "shop",
    path: "",
    component: () => import("/tmp/build/pages/shop/index.vue")
  }
]
  },
  {
    name: "stockists",
    path: "/stockists",
    component: () => import("/tmp/build/pages/stockists.vue")
  }
]