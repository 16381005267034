import type { Product, ProductVariant } from '@/types';

declare global {
  interface Window {
    fbq: any;
    _fbq: any;
  }
}

interface PixelProductView {
  content_name?: string;
  content_ids?: string[];
  content_type: string;
  currency: string;
  value: number;
}

interface PixelCartAdd {
  content_name: string;
  content_type: string;
  currency: string;
  value: number;
  contents: Array<{
    id: string;
    quantity: number;
  }>;
}

interface GoogleCartAdd {
  currency: string;
  value: number;
  items: Array<{
    item_id: string;
    item_name: string;
    // discount: 2.22,
    // index: 0,
    item_brand: string;
    item_category: string;
    // item_list_id: "related_products",
    // item_list_name: "Related Products",
    // item_variant: "green",
    // location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
    price: number;
    quantity: number;
  }>;
}

function loadFacebookPixel() {
  let pixel: any = function () {
    pixel.callMethod
      ? pixel.callMethod.apply(pixel, arguments)
      : pixel.queue.push(arguments);
  };

  window.fbq = pixel;
  window._fbq = pixel;

  pixel.push = pixel;
  pixel.loaded = true;
  pixel.version = '2.0';
  pixel.queue = [];

  const el = document.createElement('script');
  el.type = 'text/javascript';
  el.async = true;
  el.src = 'https://connect.facebook.net/en_US/fbevents.js';

  const firstScript = document.getElementsByTagName('script')[0];
  firstScript?.parentNode?.insertBefore(el, firstScript);

  return pixel;
}

export default defineNuxtPlugin({
  name: 'analytics',
  enforce: 'post',
  async setup(nuxtApp) {
    const config = useRuntimeConfig();
    let fbq: ((...args: any) => any) | null = null;

    const { pixelId } = config.public.facebook;
    if (pixelId) {
      fbq = loadFacebookPixel();
    }

    const fbInit = () => {
      fbq && fbq('init', pixelId);
    };

    const fbPageView = () => {
      fbq && fbq('track', 'PageView');
    };

    fbInit();
    fbPageView();

    const gtag = useGtag();

    return {
      provide: {
        analytics: {
          // cartAdd(product: Product, variant: ProductVariant) {
          //   fbq && fbq('track', 'ViewContent', params);
          // },
          facebook: {
            pageView: fbPageView,
            productView(product: Product) {
              const params: PixelProductView = {
                content_name: product.title,
                content_ids: product.variants?.map((v) => v.sku),
                content_type: 'product',
                currency: product.priceRange.minVariantPrice.currencyCode,
                value: Number(product.priceRange.minVariantPrice.amount),
              };
              fbq && fbq('track', 'ViewContent', params);
            },
            cartAdd(product: Product, variant: ProductVariant) {
              const params: PixelCartAdd = {
                content_name: product.title,
                currency: variant.price.currencyCode,
                content_type: 'product',
                value: Number(variant.price.amount),
                contents: [{ id: variant.sku, quantity: 1 }],
              };
              fbq && fbq('track', 'AddToCart', params);
            },
          },
          google: {
            productView() {
              // gtag('event', 'view_item', {
              //   currency,
              //   value: price,
              //   items: [
              //     {
              //       item_id: product.value.shopifyGid,
              //       item_name: name,
              //       // discount: 2.22,
              //       // index: 0,
              //       item_brand: 'Heresy',
              //       item_category: product.value.productType,
              //       // item_list_id: "related_products",
              //       // item_list_name: "Related Products",
              //       // item_variant: "green",
              //       // location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
              //       price,
              //       quantity: 1,
              //     },
              //   ],
              // });
            },
            cartAdd(product: Product, variant: ProductVariant) {
              const price = Number(variant.price.amount);
              const params: GoogleCartAdd = {
                currency: variant.price.currencyCode,
                value: price,
                items: [
                  {
                    item_id: variant.sku,
                    item_name: product.title,
                    // discount: 2.22,
                    // index: 0,
                    item_brand: 'Heresy',
                    item_category: product.productType,
                    // item_list_id: "related_products",
                    // item_list_name: "Related Products",
                    // item_variant: "green",
                    // location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
                    price,
                    quantity: 1,
                  },
                ],
              };
              gtag && gtag('event', 'add_to_cart', params);
            },
          },
        },
      },
    };
  },
});
