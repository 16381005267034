import { ref } from 'vue';
import { defineStore } from 'pinia';
import type { SanitySettings } from '@/types/sanity';
import type { ShopifySettings } from '@/types/shopify';
import { querySanitySettings } from '@/queries/sanity';
import { queryShopifyShop } from '@/queries/shopify';

export const useSettings = defineStore('settings', () => {
  const bannerVisible = ref(false);
  const meta = ref<SanitySettings['meta']>();
  const social = ref<SanitySettings['social']>([]);
  const paymentSettings = ref<ShopifySettings['paymentSettings']>({
    acceptedCardBrands: [],
    supportedDigitalWallets: [],
  });
  const text = ref<SanitySettings['text']>({
    banner: undefined,
    cookies: undefined,
    delivery: undefined,
    locale: undefined,
  });

  async function init() {
    const sanity = useSanity();
    // const { client } = useApolloClient();
    // const { data } = await useAsyncData('settings', async () => {
    //   const sanityFetch = sanity.fetch<SanitySettings>(querySanitySettings.query);
    //   const shopifyFetch = client.query<{ shop: ShopifySettings }>({
    //     query: queryShopifyShop,
    //   });
    //   const [sanityData, shopifyData] = await Promise.all([
    //     sanityFetch,
    //     shopifyFetch,
    //   ]);
    //   return { ...sanityData, ...shopifyData.data.shop };
    // });

    // Shopify doesn't send back complete paymentSettings
    // So don't bother fetching for now
    const { data } = await useAsyncData('settings', async () => {
      const sanityData = await sanity.fetch<SanitySettings>(
        querySanitySettings.query,
      );
      return sanityData;
    });

    const settings = data.value!;
    text.value = settings.text;
    bannerVisible.value = !!(
      settings.text.banner && settings.text.banner.length
    );
    meta.value = settings.meta;
    social.value = settings.social;

    // paymentSettings.value = settings.paymentSettings;
  }

  return {
    bannerVisible,
    init,
    meta,
    paymentSettings,
    social,
    text,
  };
});
